import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function deleteRoles() {
  const table = ref(null)
  const {
    successfulOperationAlert, showErrors, confirmDelete, failedOperationAlert,
  } = handleAlerts()
  const deleteRoleFromEntity = (entityId, roleId) => {
    axiosInstances.portalUsers.delete(`v1/role/${roleId}`, {
      data: {
        entity: entityId,
      },
    }).then(() => {
      successfulOperationAlert('Role is deleted successfully')
      table.value.refresh()
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  const confirmDeleteRole = (entityId, roleId) => {
    confirmDelete().then(() => {
      deleteRoleFromEntity(entityId, roleId)
    })
  }

  const isRoleAssignedToUser = (entityId, roleId) => {
    axiosInstances.portalUsers.get(`v1/role-users/${roleId}`).then(res => {
      if (res.data.data.users.length === 0) confirmDeleteRole(entityId, roleId)
      else failedOperationAlert(`You can't delete this role because these users [${res.data.data.users.map(el => el.username).toString()}] assigned to it`)
    })
  }

  return { table, isRoleAssignedToUser }
}
