import axiosInstances from '@/libs/axios-instances'

export default function entityRoles() {
  const getEntityRoles = entityId => axiosInstances.portalUsers.get(`v1/roles/entity/${entityId}`).then(res => {
    const { roles } = res.data.data
    return roles
  })

  return { getEntityRoles }
}
