<template>
  <div>

    <b-card class="p-1">
      <b-row>
        <b-col>
          <b-row align-h="end">
            <b-button
              variant="primary"
              :to="{name:'create-role-in-entity'}"
            >
              Add Role
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :items="rolesProvider"
        :fields="tableColumns"
        show-empty
        empty-text="No roles in this entity"
        responsive="lg"
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'edit-role-permission', params: { roleId: item.id , entityId: entityId } }">
              <feather-icon icon="EditIcon" />
              <span> Edit Role Permissions </span>
            </b-dropdown-item>
            <b-dropdown-item @click="isRoleAssignedToUser(entityId,item.id)">
              <feather-icon icon="XCircleIcon" />
              <span> Delete </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import entityRoles from '@/common/compositions/permissions/getEntityRoles'

import deleteRole from '@/common/compositions/permissions/deleteRoleFromEntity'

export default {
  name: 'PortalUsers',

  data() {
    return {
      entityId: this.$store.getters['mainEntity/getEntityId'],
      tableColumns: [
        { key: 'id' },
        { key: 'title' },
        { key: 'description' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { getEntityRoles } = entityRoles()

    const { table, isRoleAssignedToUser } = deleteRole()
    return {
      getEntityRoles,
      table,
      isRoleAssignedToUser,
    }
  },
  watch: {
    entityId() {
      this.$refs.table.refresh()
    },
  },
  methods: {
    rolesProvider() {
      const promise = this.getEntityRoles(this.entityId)
      return promise.then(res => {
        const roles = res
        return roles || []
      }).catch(() => [])
    },
  },
}
</script>
<style lang="scss">

</style>
